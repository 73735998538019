<template>
  <div class="home-container">
    <main-nav :scrollHeight="scrollHeight"/>
    <section class="main" style="min-width: 860px; margin: 0 auto">
      <div class="banner"></div>
      <div class="content">
        <a-row class="row">
          <a-col :span="11">
            <img src="../assets/img/official/copyright-library.jpg" alt="版权库图片">
          </a-col>
          <a-col :span="11" :offset="2">
            <h3>创建视频版权库</h3>
            <ul>
              <li>快速导入视频信息；</li>
              <li>一次创建，无限次分发授权；</li>
              <li>支持自定义分组授权，版权类型授权；</li>
              <li>下线通知一键直达所有用户；</li>
              <li>更可了解内容使用情况，随时调整资源；</li>
            </ul>
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="11">
            <h3 class="talk">与视频版权方快速合作</h3>
            <ul>
              <li>快速获取版权方的视频信息；</li>
              <li>内容实时更新；</li>
              <li>多个版权方内统一管理；</li>
            </ul>
          </a-col>
          <a-col :span="11" :offset="2">
            <img src="../assets/img/official/partner.jpg" alt="快速合作图片">
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="11">
            <img src="../assets/img/official/material.jpg" alt="海量素材图片">
          </a-col>
          <a-col :span="11" :offset="2" style="text-align: left">
            <h3 class="material">海量素材任你用</h3>
            <ul>
              <li>视频片花、标签、演员信息、封面素材一网打尽；</li>
              <li>豆瓣、IMDB、热搜信息实时获取，掌握最新动态；</li>
            </ul>
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="11">
            <h3 class="OTT">OTT/IPTV运营系统</h3>
            <ul>
              <li>与悦动网络版权联动，确保有效授权；</li>
              <li>提供多套动漫、影视视频模板，更可自创框架定制EPG；</li>
              <li>从下载到注入，轻松一键完成；</li>
              <li>快速对接各平台注入和计费；</li>
            </ul>
          </a-col>
          <a-col :span="11" :offset="2">
            <img src="../assets/img/official/OTT.jpg" alt="OTT图片">
          </a-col>
        </a-row>
      </div>
    </section>
    <footer>
      <foot/>
    </footer>
  </div>
</template>

<script>
  import MainNav from '../components/MainNav'
  import Foot from '../components/Footer'
  import {mapState} from 'vuex'

  export default {
    name: 'home-index',
    data() {
      return {
        scrollHeight: false
      }
    },
    components: {
      'main-nav': MainNav,
      'foot': Foot
    },
    computed: mapState({
      account: 'account'
    }),
    methods: {
      monitorScroll: function () {
        let scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.scrollHeight = scrollHeight
      }
    },
    mounted() {
      window.addEventListener('scroll', this.monitorScroll)
      window.scroll(0,0)
      this.scrollHeight = 0
    },
    beforeDestroy() {
      document.removeEventListener('scroll', this.monitorScroll)
    }
  }
</script>

<style lang="less" scoped>
  @import "../assets/style/var";
  @import "../assets/style/common";

  .home-container {
    section.main {
      background-color: white;

      div.banner {
        background: #f39502 url("../assets/img/official/main-background.jpg") no-repeat center;
        background-size: auto 480px;
        height: 480px;
      }

      div.content {
        width: 66.7%;
        margin: 0 auto;
        padding-bottom: 67.5px;
        .row {
          padding-top: 67.5px;
          img {
            max-width: 100%;
          }
          h3 {
            display: inline-block;
            color: #464646;
            font-size: 32px;
            background: url("../assets/img/official/copyright-library-logo.jpg") left center no-repeat;
            background-size: contain;
            padding-left: 60px;
            &.talk {
              background-image: url("../assets/img/official/partner-logo.jpg");
            }
            &.material {
              background-image: url("../assets/img/official/material-logo.jpg");
            }

            &.OTT {
              background-image: url("../assets/img/official/OTT-logo.jpg");
            }
          }
          ul{
            padding-left: 60px;
            list-style: none;
            li{
              padding-top: 22.5px;
              font-size: 16px;
              color: #aaaaaa;
            }
          }
        }
      }
    }
  }
</style>
