<template>
  <header class="main" :class="{'partner':partnerScrollHeight > 0, 'shadow': shadow, 'changeStyle': scrollHeight > 0}">
    <router-link to="/" class="logo">悦动网络</router-link>
    <ul class="menu">
      <li>
        <router-link to="/"
                     :class="{'home-link':true,'scroll':homepageCursor == '1'}">
          首页
        </router-link>
      </li>
      <li>
        <router-link to="/partner" :class="{'scroll':partnerCursor == '1'}">
          合作申请
        </router-link>
      </li>
      <li>
        <router-link to="/m">开放平台</router-link>
      </li>
    </ul>
    <ul class="user" v-if="!account">
      <li class="login"><a href="/account/login">登录 </a></li>
      <li>
        <router-link to="/account/create">注册</router-link>
      </li>
    </ul>
    <a-dropdown :trigger="['click']" style="margin-left: 15px" v-if="account">
      <a class="ant-dropdown-link" href="#">
        <a-avatar size="small"
                  :src="account.info && account.info.head? account.info.head + '?t=' + (new Date().getTime()) : ''">
          {{account.name.length>1?account.name.substr(0,1):account.name}}
        </a-avatar>
        <span style="margin-left: 0.5rem">{{account.name}}</span>
        <a-icon type="down"/>
      </a>
      <a-menu slot="overlay">
        <a-menu-item key="user-center">
          <router-link to="/user/center">
            <a-icon type="user" style="margin-right: 0.5rem"/>
            <span>个人中心</span></router-link>
        </a-menu-item>
        <a-menu-divider/>
        <a-menu-item key="user-logout">
          <router-link to="/account/login">
            <a-icon type="logout" style="margin-right: 0.5rem"/>
            退出登陆
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </header>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'main-nav',
    props: {
      'shadow': {'type': Boolean, 'default': false},
      'scrollHeight': {'type': Number, 'default': 0},
      'partnerScrollHeight': {'type': Number, 'default': 0}
    },
    data() {
      return {
        'changeStyle': false,
        'homepageCursor': false,
        'partnerCursor': false
      }
    },
    computed: mapState({
      account: 'account'
    }),
    watch: {
      scrollHeight: function (value) {
        if (value == 0) {
          this.homepageCursor = this.$route.name
        }
        if (value > 0) {
          this.changeStyle = true
          this.homepageCursor = 1
        }
      },
      partnerScrollHeight: function (value) {
        if (value == 0) {
          this.partnerCursor = this.$route.name
        }
        if (value > 0) {
          this.partnerCursor = 1
        }
      }

    }
  }
</script>

<style lang="less">
  @import "../assets/style/var";
  @import "../assets/style/common";

  header.main {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    color: white;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.shadow {
      .drop-shadow();
      background: @white-color;
      a {
        color: @primary-color;
        &:after {
          content: "";
          position: absolute;
          background: @primary-color;
          transform: scale(0);
          transition: all 0.3s;
          left: 15%;
          top:30px;
          display: inline-block;
          width: 75px;
          height: 3px;
        }
      }

    }


    a {
      color: white;

      &.logo {
        display: inline-block;
        background: url("../assets/img/logo/logo.png") no-repeat left center;
        background-size: contain;
        line-height: 40px;
        padding-left: 50px;
        font-size: 24px;
        font-weight: 800;
      }
    }

    ul {
      list-style: none;
      margin-bottom: 0;

      li {
        display: inline-block;
        position: relative;

        a {
          display: inline-block;
          padding: 0 15px;
          &:after {
            content: "";
            position: absolute;
            background: @white-color;
            transform: scale(0);
            transition: all 0.3s;
            left: 15%;
            top:30px;
            display: inline-block;
            width: 75px;
            height: 3px;
          }
          &.home-link {
            &:after {
              left: 23%;
              width: 38px;
            }
          }
          &:hover:after, &.router-link-exact-active:after {
            transform: scale(1);
          }
        }
      }

      font-size: 18px;

      &.menu {
        text-align: right;
        flex-grow: 1;
      }

      &.user {
        font-size: 16px;
        padding-right: 15px;

        .login {
          display: inline-block;
          background: url("../../src/assets/img/official/user.png") left center no-repeat;
          padding-left: 30px;

          &:after {
            content: '/';
          }
        }

        a {
          padding: 0;
          &:after {
            content: "";
            width: 0;
          }
        }
      }
    }
  }

  header.changeStyle {
    background-color: @white-color;
    color: @primary-color;
    .drop-shadow();

      a {
        color: @primary-color;

        &.scroll {
          &:after {
            position: absolute;
            left: 23%;
            top:30px;
            display: inline-block;
            content: '';
            width: 38px;
            height: 3px;
            background: @primary-color;

          }
        }
      }
  }

  header.partner {
    background: @white-color;
    color: @primary-color;
    .drop-shadow();

    a {
      color: @primary-color;

      &.scroll {
        &:after {
          position: absolute;
          left: 15%;
          top:30px;
          display: inline-block;
          content: '';
          width: 75px;
          height: 3px;
          background: @primary-color;
        }
      }
    }
  }


</style>
