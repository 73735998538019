<template>
  <div class="foot">
    <div class="other-info">
      <div class="main">
        <a-row class="row">
          <a-col :span="8">
            <h4>悦动网络 - 让内容创造更多价值</h4>
            <p>悦动网络是国内专业的视频授权分发平台，为视频行业的用户提供了方便快捷的授权分发解决方案。让内容更高效的利用，创造更多的价值。</p>
          </a-col>
          <a-col :span="2" :offset="4">
            <h3>快速链接</h3>
            <ul>
              <li>
                <router-link to="/m">开放平台</router-link>
              </li>
              <li><a href="http://res-base.guazi.video/app/node-download.jar" target="_blank">下载工具</a></li>
              <li><a href="#" disabled>转码工具</a></li>
            </ul>
          </a-col>
          <a-col :span="6" :offset="3">
            <h3>联系我们</h3>
            <ul>
              <li>合作申请</li>
              <li>官网：www.yuedongtv.cn</li>
              <li>邮箱：contact@yuedongtv.cn</li>
              <li>地址：成都市武侯区航空路国航世纪中心B座801</li>
            </ul>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="foot-link">
      <span>Copyright©2019-{{currentYear}} 悦动网络 All rights reserved. </span>
      <a target="_blank" href="http://beian.miit.gov.cn/">蜀ICP备17032491号-2</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data () {
      return {
        "currentYear": new Date().getFullYear()
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../src/assets/style/var.less";
  @import "../../src/assets/style/common.less";

  .foot {
    font-size: 14px;
    padding: 20px 45px;
    background-color: #333333;
    width: 100%;

    .foot-link {
      border-top: 1px solid #565656;
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;

      a, span {
        color: #aaaaaa
      }

    }

    div.other-info {
      background: #333333;
      padding-top: 20px;
      padding-bottom: 70px;
      color: #ffffff;
      font-size: 14px;

      a {
        color: #ffffff;

        &
        :hover {
          color: @primary-color;
        }

      }

      .row {
        position: relative;

        h4 {
          background: url("../assets/img/official/guazi-logo.png") no-repeat left center;
          background-size: contain;
          height: 60px;
          text-indent: -9999px;
        }

        h3 {
          color: #ffffff;
          font-size: 26px;
        }

        p {
          margin-top: 20px;
          line-height: 22.5px;
        }

        ul {
          list-style: none;
          padding-left: 0;

          li {
            padding-top: 22.5px;
          }

        }
      }
    }
  }

</style>
